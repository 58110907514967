<script>
import { storeForms } from '@app/mixins/forms';
import CertificateField from '@app/components/CertificateField.vue';

export default {
  mixins: [storeForms],
  components: { CertificateField },
  data() {
    return {
      isLoading: false,
      store: {
        certificate_options: {},
      },
    };
  },
};
</script>

<template>
  <div>
    <AppFeatureWrapper tag="section">
      <template #title>
        Choisissez le modèle du certificat de réussite
      </template>
      <template #content>
        <p>
          Le certificat de réussite est un document que vos apprenants <strong>pourront
          générer eux-mêmes</strong>, uniquement lorsqu'ils auront terminé
          toutes les leçons d'une formation.
        </p>
        <p>
          Ce document est <strong>illustratif</strong>
          et n'a par conséquent aucune valeur juridique.
        </p>
      </template>
      <template #form>
        <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
          <figure class="image">
            <img :src="require(
              `@app/assets/img/certificates/${store.certificate_options.template_page_number}.png`
            )" />
          </figure>
          <h3 class="title is-5 mt-5">
            Choisissez le modèle du certificat de réussite
          </h3>
          <GoProWrapper :hasSep="false">
            <b-field>
              <CertificateField
                v-model="store.certificate_options.template_page_number"
              />
            </b-field>
            <b-field class="has-text-right">
              <b-button type="is-primary" native-type="submit" :loading="isLoading">
                Mettre à jour
              </b-button>
            </b-field>
          </GoProWrapper>
        </form>
      </template>
    </AppFeatureWrapper>
    <AppFeatureWrapper tag="section">
      <template #title>
        Désactivez les certificats de réussite
      </template>
      <template #content>
        <p>
          Vous ne voulez pas délivrer de certificats de réussite à vos apprenants ?
          <strong>Vous êtes au bon endroit !</strong>
        </p>
      </template>
      <template #form>
        <div class="box is-relative">
          <div class="notification">
            <p>
              Vous pouvez désactiver cette fonctionnalité.
            </p>
            <p class="has-text-danger has-text-weight-bold">
              Attention ! Les certificats déjà générés ne seront pas supprimés.
            </p>
          </div>

          <b-switch
            v-model="store.certificate_options.disabled"
            @input="dataIsValid(isLoading) && handle()"
          >
            Désactiver les certificats
          </b-switch>

          <b-loading v-model="isLoading" :is-full-page="false" />
        </div>
      </template>
    </AppFeatureWrapper>
  </div>
</template>
